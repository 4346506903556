<script setup>
import { ref, onMounted, watch, reactive } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from "primevue/useconfirm"
import { FilterMatchMode } from 'primevue/api'
import axios from 'axios'
import { getRandomId } from '@/core/var/tools'

/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

// filters
const filter_menu = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const changes = ref(0)
const subchanges = ref(0)

//////////////////
// Data Sources //
//////////////////

const menu = ref([])
const expanded_submenus = ref([])
const edit_submenu_dialog = ref(false)
const edit_page_standard_dialog = ref(false)
const edit_page_team_dialog = ref(false)
const edit_page_references_dialog = ref(false)
const submenu = ref([])
const menu_index = ref()
const menu_parent = ref()
const page = ref({})
const page_template = ref('static')
const page_slug = ref()
const templates = ref([
    {name: 'Statisch', value: 'static'},
    {name: 'Standard', value: 'standard'},
    {name: 'Referenzen', value: 'references'},
    {name: 'Team', value: 'team'}
])

onMounted(() => {
    get_menu()
})

const get_menu = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gartenkultur/website/get-menu')
    .then(response => {
        menu.value = response.data
        loader.value = false
        changes.value = 0
    })
}

const edit_menu = (e) => {
    //console.log(e)
    menu.value[e.index] = e.newData
    changes.value++
    toast.add({severity: 'success', summary: 'Menüpunkt geändert', life: 3000})
}

const menu_reorder = (e) => {
    menu.value = e.value
    changes.value++
    toast.add({severity: 'success', summary: 'Reihenfolge geändert', life: 3000})
}

const edit_submenus = (e) => {
    //console.log(e)
    submenu.value = e.data.submenu
    menu_index.value = e.index
    menu_parent.value = e.data.parent
    edit_submenu_dialog.value = true
}

const edit_submenu = (e) => {
    submenu.value[e.index] = e.newData
    subchanges.value++
    toast.add({severity: 'success', summary: 'Untermenüpunkt geändert', life: 3000})
}

const submenu_reorder = (e) => {
    submenu.value = e.value
    subchanges.value++
    toast.add({severity: 'success', summary: 'Reihenfolge geändert', life: 3000})
}

const delete_menu = (id) => {
    confirm.require({
        message: 'Möchten Sie diesen Menüpunkt wirklich löschen?',
        header: 'Bestätigung',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, löschen!',
        rejectLabel: 'Nein',
        accept: () => {
            menu.value.splice(id, 1)
            changes.value++
            toast.add({severity: 'success', summary: 'Menüpunkt gelöscht', life: 3000})
        }
    })
}

const delete_submenu = (id) => {
    confirm.require({
        message: 'Möchten Sie diesen Untermenüpunkt wirklich löschen?',
        header: 'Bestätigung',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, löschen!',
        rejectLabel: 'Nein',
        accept: () => {
            submenu.value.splice(id, 1)
            subchanges.value++
            toast.add({severity: 'success', summary: 'Untermenüpunkt gelöscht', life: 3000})
        }
    })
}

const save_menu = () => {
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gartenkultur/website/save-menu', menu.value)
    .then(response => {
        changes.value = 0
        toast.add({severity: 'success', summary: 'Menü gespeichert', life: 3000})
    })
}

const save_submenu = () => {
    menu.value[menu_index.value].submenu = submenu.value
    subchanges.value = 0
    changes.value++

    toast.add({severity: 'success', summary: 'Untermenü gespeichert', life: 3000})
}

const add_menu = () => {
    menu.value = [...menu.value, {title: '', slug: '', template: 'static', submenu: []}]
    changes.value++
}

const add_submenu = (data) => {
    //console.log(data)
    submenu.value = [...submenu.value, {title: '', slug: '', template: 'static', parent: menu_parent.value, icon: '', card: ''}]
    subchanges.value++
}

const edit_page = (data) => {
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gartenkultur/website/edit-page', {slug: data.slug})
    .then(response => {
        page.value = response.data
    
        loader.value = false
        if (data.template == 'standard') {
            page_slug.value = data.slug
            page_template.value = data.template
            edit_page_standard_dialog.value = true
        }
        if (data.template == 'team') {
            page_slug.value = data.slug
            page_template.value = data.template
            edit_page_team_dialog.value = true
        }
        if (data.template == 'references') {
            page.value.references = page
            page_slug.value = data.slug
            page_template.value = data.template
            edit_page_references_dialog.value = true
        }
    })
}

const save_page = () => {
    page.value.slug = page_slug.value
    page.value.template = page_template.value
    //console.log(page.value.template, page.value, page_slug.value)
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gartenkultur/website/save-page', page.value)
    .then(response => {
        toast.add({severity: 'success', summary: 'Seite gespeichert', life: 3000})
    })
}
const save_references = () => {
    page.value.slug = page_slug.value
    page.value.template = page_template.value
    //console.log( page.value.references)
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/gartenkultur/website/save-page-references', page.value.references)
        .then(response => {
            toast.add({ severity: 'success', summary: 'Seite gespeichert', life: 3000 })
        })
}



const add_page_slider = () => {
    if (page.value.slider == null) {
        page.value.slider = []
    }
    page.value.slider = [...page.value.slider, {image: ''}]
}

const page_slider_reorder = (e) => {
    page.value.slider = e.value
    toast.add({severity: 'success', summary: 'Reihenfolge geändert', life: 3000})
}

const delete_page_slider = (data) => {
    confirm.require({
        message: 'Möchten Sie dieses Bild wirklich löschen?',
        header: 'Bestätigung',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, löschen!',
        rejectLabel: 'Nein',
        accept: () => {
            page.value.slider.splice(data.index, 1)
            toast.add({severity: 'success', summary: 'Bild gelöscht', life: 3000})
        }
    })
}

const add_page_team = () => {
    if (page.value.team == null) {
        page.value.team = []
    }
    page.value.team = [...page.value.team, {image: '', name: '', role: '', email: ''}]
}

const edit_page_team = (e) => {
    page.value.team[e.index] = e.newData
    toast.add({severity: 'success', summary: 'Teammitglied geändert', life: 3000})
}

const page_team_reorder = (e) => {
    page.value.team = e.value
    toast.add({severity: 'success', summary: 'Reihenfolge geändert', life: 3000})
}

const delete_page_team = (data) => {
    confirm.require({
        message: 'Möchten Sie dieses Teammitglied wirklich löschen?',
        header: 'Bestätigung',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, löschen!',
        rejectLabel: 'Nein',
        accept: () => {
            page.value.team.splice(data.index, 1)
            toast.add({severity: 'success', summary: 'Teammitglied gelöscht', life: 3000})
        }
    })
}

const add_page_reference = () => {
    if (page.value.references == null) {
        page.value.references = []
    }
    page.value.references = [...page.value.references, {image: '', title: '', slug: ''}]
}

const edit_page_reference = (e) => {
    page.value.references[e.index] = e.newData
    toast.add({severity: 'success', summary: 'Referenz geändert', life: 3000})
}

const page_reference_reorder = (e) => {
    page.value.references = e.value
    toast.add({severity: 'success', summary: 'Reihenfolge geändert', life: 3000})
}

const delete_page_reference = (data) => {
    confirm.require({
        message: 'Möchten Sie diese Referenz wirklich löschen?',
        header: 'Bestätigung',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, löschen!',
        rejectLabel: 'Nein',
        accept: () => {
            page.value.references.splice(data.index, 1)
            toast.add({severity: 'success', summary: 'Referenz gelöscht', life: 3000})
        }
    })
}

const file_upload = (e, filename, path, post, index) => {
    let reader = new FileReader()
    let file = null
    reader.readAsDataURL(e.files[0])
    reader.onload = () => {
        file = reader.result
        let payload = {
            "file": file,
            "filename": filename,
            "path": path
        }
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/gartenkultur/website/upload-file", payload)
            .then(response => {
                if (post == 'submenu_icon') {
                    submenu.value[index].icon = response.data.url
                    subchanges.value++
                }
                if (post == 'submenu_card') {
                    submenu.value[index].card = response.data.url
                    subchanges.value++
                }
                if (post == 'standard_page_banner') {
                    page.value.banner = response.data.url
                }
                if (post == 'block_1_image') {
                    page.value.block_1_image = response.data.url
                }
                if (post == 'block_2_image') {
                    page.value.block_2_image = response.data.url
                }
                if (post == 'standard_slider') {
                    page.value.slider[index].image = response.data.url
                }
                if (post == 'team_image') {
                    page.value.team[index].image = response.data.url
                }
                if (post == 'reference_image') {
                    page.value.references[index].image = response.data.url
                    edit_reference_single_page_data.image = response.data.url
                }
                if (post == 'reference_image_block2') {
                    edit_reference_single_page_data.data.block2.image = response.data.url
                }
                if (post == 'reference_image_topimage') {
                    //console.log('reference_image_topimage', response.data.url)
                    edit_reference_single_page_data.data.topimage = response.data.url
                }
                if (post == 'reference_image_slider') {
                    console.log('reference_image_slider', response.data.url)
                }
                if (post == 'reference_image_gallery') {
                    //console.log('reference_image_gallery', response.data.url)
                    edit_reference_single_page_data.data.images[index] = response.data.url
                    //edit_reference_single_page_data.data.images.push(response.data.url)
                }
                if (post == 'reference_image_slider') {
                    //console.log('reference_image_slider', response.data.url)
                    edit_reference_single_page_data.data.slider[index] = {'image':response.data.url}
                }
            }).catch(error => {
            console.error("There was an error!", error.message);
        })
    }
}

const index_vs_length = (index, slug) => {
    let checker = false
    menu.value.forEach(element => {
        element.forEach(sub_element => {
            if (sub_element.slug == slug) {
                if (index < element.length - 1) {
                    checker = true
                }
            }
        })
    })
    return checker
}

const edit_reference_page_data = ref()
const edit_page_references_dialog_single = ref(false)
//const edit_reference_select_theme = ref()
const edit_reference_select_theme_options = ref([
    { theme: 'detail1' },
    { theme: 'detail2' },
    { theme: 'detail3' }
])

const edit_reference_single_page_data = reactive({
    data: {
        lead: '',
        sub: '',
        topimage: '',
        images: [],
        block1:{
            title: '',
            text: '',
            image: '',
        } ,
        block2: {
            image: '',
        },
        slider: [],
    }
})

const edit_reference = (data, index) => {
    edit_page_references_dialog_single.value = true
    edit_reference_page_data.value = data
    mapDataToEditReferenceSinglePageData(data, index)
}
const mapDataToEditReferenceSinglePageData = (data, index) => {
    edit_reference_single_page_data.index = index
    edit_reference_single_page_data.title = data.title || '';
    edit_reference_single_page_data.slug = data.slug || '';
    edit_reference_single_page_data.image = data.image || '';
    edit_reference_single_page_data.theme = data.theme || '';
    edit_reference_single_page_data.data.lead = data.data?.lead || '';
    edit_reference_single_page_data.data.sub = data.data?.sub || '';
    edit_reference_single_page_data.data.topimage = data.data?.topimage || '';
    edit_reference_single_page_data.data.images = data.data?.images || [];
    edit_reference_single_page_data.data.block1.title = data.data?.block1?.title || '';
    edit_reference_single_page_data.data.block1.text = data.data?.block1?.text || '';
    edit_reference_single_page_data.data.block1.image = data.data?.block1?.image || '';
    edit_reference_single_page_data.data.block2.image = data.data?.block2?.image || '';
    edit_reference_single_page_data.data.block2.title = data.data?.block2?.title || '';
    edit_reference_single_page_data.data.block2.text = data.data?.block2?.text || '';
    edit_reference_single_page_data.data.slider = data.data?.slider || [];
};

const save_references_single_page = () => {
    //console.log('edit_reference_single_page_data', edit_reference_single_page_data);
    const index = edit_reference_single_page_data.index;
    if (index !== undefined && index >= 0 && index < page.value.references.length) {
        page.value.references[index] = { ...edit_reference_single_page_data };
        //console.log(page.value.references);

    } else {
        console.error('Invalid index:', index);
    }
    save_references()
    edit_page_references_dialog_single.value = false
}

const addImage = () => {
    edit_reference_single_page_data.data.images.push('');
}
const addSliderImage = () => {
    edit_reference_single_page_data.data.slider.push({image:''});
}
const removeImage = (index) => {
    edit_reference_single_page_data.data.images.splice(index, 1);
};
const removeSliderImage = (index) => {
    edit_reference_single_page_data.data.slider.splice(index, 1);
}

const slugify = (text) => {
    return text.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w-]+/g, '')       // Remove all non-word chars
        .replace(/--+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')           // Trim - from start of text
        .replace(/-+$/, '')           // Trim - from end of text
        .toLowerCase()              // Lowercase
}
</script>
 <style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style> 
<template>
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <div class="overflow-hidden rounded mt-4 p-2 flex items-start w-1/3">
        <Button icon="pi pi-refresh" @click="get_menu()" class="mr-2 w-auto w-3rem" />
        <Button :disabled="changes == 0 ? true : false" label="Speichern" icon="pi pi-save" class="mr-2 w-auto p-button-success" :badge="String(changes)" badgeSeverity="danger" @click="save_menu()" />
        <Button label="Menu" icon="pi pi-plus" class="mr-2 w-auto" @click="add_menu()" />
    </div>
    <div rounded class="overflow-hidden shadow-4 rounded border-200 mt-4">
        <DataTable v-model:filters="filter_menu" v-model:expandedRows="expanded_submenus" :value="menu" responsiveLayout="scroll" editMode="cell" @cell-edit-complete="edit_menu" :rowHover="true" @rowReorder="menu_reorder">
            <Column expander style="width: 4rem" />
            <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
            <Column field="title" header="Titel" style="">
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" />
                </template>
                <template #body="{ data, field }">
                    <span class="font-bold">{{data[field]}}</span>
                    <Badge v-if="data.submenu.length > 0" :value="String(data.submenu.length)" severity="info" class="ml-2" />
                </template>
            </Column>
            <Column field="slug" header="URL" style="">
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" />
                </template>
                <template #body="{ data, field }">
                    {{data[field]}}
                </template>
            </Column>
            <Column field="template" header="Template" style="">
                <template #editor="{ data, field }">
                    <Dropdown v-model="data[field]" :options="templates" optionLabel="name" optionValue="value" />
                </template>
                <template #body="{ data, field }">
                    {{data[field]}}
                </template>
            </Column>
            <Column>
                <template #body="slotProps">
                    <Button :disabled="slotProps.data.submenu.length == 0 ? true : false" icon="pi pi-table" class="mr-2 p-button-rounded p-button-text" @click="edit_submenus(slotProps)" v-tooltip.top="'Untermenu editieren'" />
                    <Button v-if="slotProps.data.template != 'static'" icon="pi pi-pencil" class="mr-2 p-button-rounded p-button-text" @click="edit_page(slotProps.data)" v-tooltip.top="'Inhalt editieren'" />
                    <Button icon="pi pi-trash" class="mr-2 -button-rounded p-button-text p-button-danger" @click="delete_menu(slotProps.index)" v-tooltip.top="'Menu löschen'" />
                </template>
            </Column>
            <template #expansion="slotProps">
                <div rounded class="overflow-hidden shadow-3 border-200">
                    <DataTable :value="slotProps.data.submenu" responsiveLayout="scroll" editMode="cell" :rowHover="true">
                        <Column style="width: 100px">
                            <template #body="slotProps">
                                <Image preview :src="slotProps.data.icon" class="w-3rem h-3rem shadow-2" v-tooltip.right="{ value: '<img src=\'' + slotProps.data.icon + '\' />', escape: false}" />
                            </template>
                        </Column>
                        <Column field="title" header="Submenu-Titel" style="">
                            <template #body="slotProps">
                                <span class="font-bold">{{slotProps.data.title}}</span>
                            </template>
                        </Column>
                        <Column field="slug" header="URL" style="">
                            <template #body="slotProps">
                                {{slotProps.data.slug}}
                            </template>
                        </Column>
                        <Column field="template" header="Template" style="">
                            <template #body="slotProps">
                                {{slotProps.data.template}}
                            </template>
                        </Column>
                        <Column field="parent" hidden />
                        <Column>
                            <template #body="slotProps">
                                <Button icon="pi pi-pencil" class="mr-2 p-button-rounded p-button-text" @click="edit_page(slotProps.data)" v-tooltip.top="'Inhalt editieren'" />
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </template>
        </DataTable>
    </div>
    <Dialog v-model:visible="edit_submenu_dialog" :style="{width: '80%'}" header="Untermenu editieren" :modal="true" class="p-fluid z-5">
        <DataTable :value="submenu" responsiveLayout="scroll" editMode="cell" @cell-edit-complete="edit_submenu" :rowHover="true" @rowReorder="submenu_reorder">
            <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
            <Column header="Icon" style="width: 200px">
                <template #body="slotProps">
                    <Image preview :src="slotProps.data.icon" class="w-full shadow-2 mr-2" v-tooltip.right="{ value: '<img src=\'' + slotProps.data.icon + '\' />', escape: false}" />
                    <FileUpload mode="basic" name="file[]" url="/" accept="image/*" :maxFileSize="10000000" :auto="true" chooseLabel="Hochladen" customUpload @uploader="file_upload($event, slotProps.data.slug + '_icon.webp', 'website/images/', 'submenu_icon', slotProps.index)" />
                </template>
            </Column>
            <Column header="Card" style="width: 200px">
                <template #body="slotProps">
                    <Image preview :src="slotProps.data.card" class="w-full shadow-2 mr-2" v-tooltip.right="{ value: '<img src=\'' + slotProps.data.card + '\' />', escape: false}" />
                    <FileUpload mode="basic" name="file[]" url="/" accept="image/*" :maxFileSize="10000000" :auto="true" chooseLabel="Hochladen" customUpload @uploader="file_upload($event, slotProps.data.slug + '_card.webp', 'website/images/', 'submenu_card', slotProps.index)" />
                </template>
            </Column>
            <Column field="title" header="Titel" style="">
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" />
                </template>
                <template #body="{ data, field }">
                    <span class="font-bold">{{data[field]}}</span>
                </template>
            </Column>
            <Column field="slug" header="URL" style="">
                <template #editor="{ data, field }">
                    <InputText v-model="data[field]" />
                </template>
                <template #body="{ data, field }">
                    {{data[field]}}
                </template>
            </Column>
            <Column field="template" header="Template" style="">
                <template #editor="{ data, field }">
                    <Dropdown v-model="data[field]" :options="templates" optionLabel="name" optionValue="value" />
                </template>
                <template #body="{ data, field }">
                    {{data[field]}}
                </template>
            </Column>
            <Column field="parent" hidden />
            <Column>
                <template #body="slotProps">
                    <Button icon="pi pi-trash" class="mr-2 -button-rounded p-button-text p-button-danger" @click="delete_submenu(slotProps.index)" v-tooltip.top="'Menu löschen'" />
                </template>
                <template #header="slotProps">
                    <Button icon="pi pi-plus" class="mr-2 -button-rounded p-button-text" @click="add_submenu(slotProps)" v-tooltip.top="'Submenu hinzufügen'" />
                </template>
            </Column>
        </DataTable>
        <div class="flex justify-end mt-4">
            <Button :disabled="subchanges == 0 ? true : false" label="Speichern" icon="pi pi-save" class="w-auto p-button-success mr-2" @click="save_submenu()" :badge="String(subchanges)" badgeSeverity="danger" />
            <Button label="Abbrechen" class="w-auto p-button-danger" @click="edit_submenu_dialog = false, submenu = null, subchanges = 0" />
        </div>
    </Dialog>
    <!-- EDITOR STANDARD -->
    <Dialog v-model:visible="edit_page_standard_dialog" :style="{width: '80%'}" header="Seite editieren" :modal="true" class="p-fluid z-5">
        <div class="col-12 formgrid grid">
            <div class="field col-12">
                <Image preview :src="page.banner" class="w-full shadow-2" />
                <FileUpload mode="basic" name="file[]" url="/" accept="image/*" :maxFileSize="10000000" :auto="true" chooseLabel="Banner Bild" customUpload @uploader="file_upload($event, page_slug + '_banner.webp', 'website/images/', 'standard_page_banner', 0)" />
            </div>
            <div class="field col-12">
                <span class="p-float-label">
                    <InputText id="main_title" v-model="page.main_title" class="mt-3 w-full" />
                    <label for="main_title">Haupt-Titel</label>
                </span>
            </div>
            <div class="field col-12"><span class="font-bold text-xl">Block 1</span></div>
            <div class="field col-6">
                <span class="p-float-label">
                    <InputText id="title" v-model="page.block_1_subtitle" class="mt-3 w-full" />
                    <label for="title">Untertitel</label>
                </span>
                <span class="p-float-label">
                    <InputText id="title" v-model="page.block_1_title" class="mt-3 w-full" />
                    <label for="title">Titel</label>
                </span>
                <Editor v-model="page.block_1_text" class="mt-3 w-full">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                            <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                            <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                            <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                            <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                            <button class="ql-code" v-tooltip.bottom="'Code'"></button>
                        </span>
                    </template>
                </Editor>
            </div>
            <div class="field col-6">
                <Image preview :src="page.block_1_image" class="w-full shadow-2" />
                <FileUpload mode="basic" name="file[]" url="/" accept="image/*" :maxFileSize="10000000" :auto="true" chooseLabel="Block 1 Bild" customUpload @uploader="file_upload($event, page_slug + '_block_1_image.webp', 'website/images/', 'block_1_image', 0)" />
            </div>
            <div class="field col-12"><span class="font-bold text-xl">Slider</span></div>
            <div class="field col-12">
                <DataTable :value="page.slider" class="w-full" @rowReorder="page_slider_reorder">
                    <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                    <Column field="image" header="Bild" style="width: 40%">
                        <template #body="slotProps">
                            <Image preview :src="slotProps.data.image" class="w-full shadow-2" />
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <FileUpload mode="basic" name="file[]" url="/" accept="image/*" :maxFileSize="10000000" :auto="true" chooseLabel="Slider Bild" customUpload @uploader="file_upload($event, page_slug + '_slider_'+String(slotProps.index)+'.webp', 'website/images/', 'standard_slider', slotProps.index)" />
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <Button icon="pi pi-trash" class="mr-2 p-button-rounded p-button-text p-button-danger" v-tooltip.top="'Bild löschen'" @click="delete_page_slider(slotProps)" />
                        </template>
                        <template #header="slotProps">
                            <Button icon="pi pi-plus" class="mr-2 p-button-rounded p-button-text" v-tooltip.top="'Bild hinzufügen'" @click="add_page_slider(slotProps)" />
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="field col-12"><span class="font-bold text-xl">Block 2</span></div>
            <div class="field col-6">
                <Image preview :src="page.block_2_image" class="w-full shadow-2" />
                <FileUpload mode="basic" name="file[]" url="/" accept="image/*" :maxFileSize="10000000" :auto="true" chooseLabel="Block 2 Bild" customUpload @uploader="file_upload($event, page_slug + '_block_2_image.webp', 'website/images/', 'block_2_image', 0)" />
            </div>
            <div class="field col-6">
                <span class="p-float-label">
                    <InputText id="title" v-model="page.block_2_subtitle" class="mt-3 w-full" />
                    <label for="title">Untertitel</label>
                </span>
                <span class="p-float-label">
                    <InputText id="title" v-model="page.block_2_title" class="mt-3 w-full" />
                    <label for="title">Titel</label>
                </span>
                <Editor v-model="page.block_2_text" class="mt-3 w-full">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                            <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                            <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                            <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                            <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                            <button class="ql-image" v-tooltip.bottom="'Bild hochladen'"></button>
                        </span>
                    </template>
                </Editor>
            </div>
            <div class="field col-12">
                <Button label="Speichern" icon="pi pi-save" class="w-auto p-button-success mr-2" @click="save_page(), edit_page_standard_dialog = false" />
                <Button label="Abbrechen" class="w-auto p-button-danger" @click="edit_page_standard_dialog = false, page = null" />
            </div>
        </div>
    </Dialog>

    <!-- TEAM STANDARD -->
    <Dialog v-model:visible="edit_page_team_dialog" :style="{width: '80%'}" header="Team editieren" :modal="true" class="p-fluid z-5">
        <div class="col-12 formgrid grid">
            <div class="field col-12">
                <DataTable :value="page.team" class="w-full" editMode="cell" @cell-edit-complete="edit_page_team" @rowReorder="page_team_reorder">
                    <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                    <Column field="image" header="Bild" style="width: 20%">
                        <template #body="slotProps">
                            <Image preview :src="slotProps.data.image" class="w-full shadow-2" />
                            <FileUpload mode="basic" name="file[]" url="/" accept="image/*" :maxFileSize="10000000" :auto="true" chooseLabel="Team Bild" customUpload @uploader="file_upload($event, page_slug + '_team_'+slugify(slotProps.data.name)+'.webp', 'website/images/', 'team_image', slotProps.index)" />
                        </template>
                    </Column>
                    <Column field="name" header="Name">
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            <span class="font-bold">{{data[field]}}</span>
                        </template>
                    </Column>
                    <Column field="role" header="Rolle">
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            <span class="font-bold">{{data[field]}}</span>
                        </template>
                    </Column>
                    <Column field="email" header="E-Mail">
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            <span class="font-bold">{{data[field]}}</span>
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <Button icon="pi pi-trash" class="mr-2 p-button-rounded p-button-text p-button-danger" v-tooltip.top="'Team löschen'" @click="delete_page_team(slotProps)" />
                        </template>
                        <template #header="slotProps">
                            <Button icon="pi pi-plus" class="mr-2 p-button-rounded p-button-text" v-tooltip.top="'Team hinzufügen'" @click="add_page_team(slotProps)" />
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="field col-12">
                <Button label="Speichern" icon="pi pi-save" class="w-auto p-button-success mr-2" @click="save_page(), edit_page_team_dialog = false" />
                <Button label="Abbrechen" class="w-auto p-button-danger" @click="edit_page_team_dialog = false, page = null" />
            </div>
        </div>
    </Dialog>

    <!-- TEAM Referenzen -->
    <Dialog v-model:visible="edit_page_references_dialog" :style="{width: '80%'}" header="Referenzen editieren" :modal="true" class="p-fluid z-5">
        <div class="col-12 formgrid grid">
            <div class="field col-12">
                <DataTable :value="page.references" class="w-full" editMode="cell" @cell-edit-complete="edit_page_reference" @rowReorder="page_reference_reorder">
                    <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                    <Column field="image" header="Bild" style="width: 20%">
                        <template #body="slotProps">
                            <Image preview :src="slotProps.data.image" class="w-full shadow-2" />
                            <!-- <FileUpload mode="basic" name="file[]" url="/" accept="image/*" :maxFileSize="10000000" :auto="true" chooseLabel="Referenz Bild" customUpload @uploader="file_upload($event, page_slug + '_reference_'+String(slotProps.index)+'.webp', 'website/images/', 'reference_image', slotProps.index)" /> -->
                        </template>
                    </Column>
                    <Column field="title" header="Titel" style="">
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            <span class="font-bold">{{data[field]}}</span>
                        </template>
                    </Column>
                    <Column field="slug" header="URL" style="">
                        <template #editor="{ data, field }">
                            <InputText v-model="data[field]" />
                        </template>
                        <template #body="{ data, field }">
                            <span class="font-bold">{{data[field]}}</span>
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="mr-2 p-button-rounded p-button-text" v-tooltip.top="'Referenz editieren'" @click="edit_reference(slotProps.data, slotProps.index)" />

                            <Button icon="pi pi-trash" class="mr-2 p-button-rounded p-button-text p-button-danger" v-tooltip.top="'Referenz löschen'" @click="delete_page_reference(slotProps)" />

                        </template>
                        <template #header="slotProps">
                            <Button icon="pi pi-plus" class="mr-2 p-button-rounded p-button-text" v-tooltip.top="'Referenz hinzufügen'" @click="add_page_reference(slotProps)" />
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="field col-12">
                <Button label="Speichern" icon="pi pi-save" class="w-auto p-button-success mr-2" @click="save_references(), edit_page_references_dialog = false" />
                <Button label="Abbrechen" class="w-auto p-button-danger" @click="edit_page_references_dialog = false, page = null" />
            </div>
        </div>
    </Dialog>

    <Dialog v-model:visible="edit_page_references_dialog_single" :style="{ width: '80%' }" header="Referenz editieren" :modal="true" class="p-fluid z-5">

        <h2>{{ edit_reference_single_page_data.theme }}</h2>
        <div class="bg-gray-200 p-3 rounded-lg my-2">
            <Dropdown v-model="edit_reference_single_page_data.theme" :options="edit_reference_select_theme_options" optionLabel="theme" optionValue="theme" />
        </div>

        <div v-if="edit_reference_single_page_data.theme == 'detail1'">
            <form @submit.prevent="submitForm">

                <section class="bg-gray-200 p-3 rounded-lg my-2">
                    <h3 class=" font-medium text-xl">Meta</h3>
                    <div class="my-2">
                        <label for="title">Titel</label>
                        <InputText id="title" v-model="edit_reference_single_page_data.title" class="w-full" />
                    </div>
                    <div class="my-2">
                        <label for="slug">Slug</label>
                        <InputText id="slug" v-model="edit_reference_single_page_data.slug" class="w-full" />
                    </div>
                    <div class="my-2">
                        <label for="image">Image URL</label>
                        <Image preview :src="edit_reference_single_page_data.image" class="w-full shadow-2" />
                        <FileUpload mode="basic" name="file[]" url="/" accept="image/*" :maxFileSize="10000000" :auto="true" chooseLabel="Referenz Bild" customUpload @uploader="file_upload($event, edit_reference_single_page_data.slug + '_reference_' + String(edit_reference_single_page_data.index) + '.webp', 'website/images/', 'reference_image', edit_reference_single_page_data.index)" />
                    </div>
                </section>

                <section class="bg-gray-200 p-3 rounded-lg my-2">
                    <div class="p-field">
                        <label for="lead">Lead</label>
                        <InputText id="lead" v-model="edit_reference_single_page_data.data.lead" class="w-full" />
                    </div>
                    <div class="p-field">
                        <label for="sub">Sub</label>
                        <InputText id="sub" v-model="edit_reference_single_page_data.data.sub" class="w-full" />
                    </div>
                </section>

                <section class="bg-gray-200 p-3 rounded-lg my-2">
                    <div class="p-field">
                        <label for="block1Title">Block 1 Titel</label>
                        <InputText id="block1Title" v-model="edit_reference_single_page_data.data.block1.title" class="w-full" />
                    </div>
                    <div class="p-field">
                        <label for="block1Text">Block 1 Text</label>
                        <Textarea id="block1Text" v-model="edit_reference_single_page_data.data.block1.text" class="w-full" rows="4" />
                    </div>
                </section>
                <section class="bg-gray-200 p-3 rounded-lg my-2">
                    <div class="p-field">
                        <label for="topimage">Top Image</label>
                        <!-- <InputText id="topimage" v-model="edit_reference_single_page_data.topimage" class="w-full" /> -->
                        <Image preview :src="edit_reference_single_page_data.data.topimage" class="w-full shadow-2" v-if="edit_reference_single_page_data.data.topimage" />
                        <FileUpload mode="basic" name="file[]" url="/" accept="image/*" :maxFileSize="10000000" :auto="true" chooseLabel="Top Image" customUpload @uploader="file_upload($event, edit_reference_single_page_data.slug + '_reference_' + String(getRandomId(6)) + '.webp', 'website/images/', 'reference_image_topimage', edit_reference_single_page_data.index)" />
                    </div>

                    <section class="bg-gray-200 p-3 rounded-lg my-2">
                        <div class="p-field">
                            <label for="images">Slider</label>
                            <div class="my-4">
                                <div class="flex flex-wrap ">
                                    <div v-for="(image, index) in edit_reference_single_page_data.data.slider" :key="index" class="p-2 w-1/2 sm:w-1/3 lg:w-1/4">
                                        <div class="relativ">
                                            <Image preview :src="image.image" class="w-full h-full object-cover shadow-2" v-if="image.image" />
                                            <FileUpload mode="basic" name="file[]" url="/" accept="image/*" :maxFileSize="10000000" :auto="true" chooseLabel="Slider Bild" customUpload @uploader="(event) => file_upload(event, `${edit_reference_single_page_data.slug}_reference_${getRandomId(6)}.webp`, 'website/images/', 'reference_image_slider', index)" class=" cursor-pointer" />
                                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-outlined absolut top-2 right-2" @click="removeSliderImage(index)" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="my-4">
                                <Button label="Neues Bild" icon="pi pi-plus" class="p-button-outlined h-full w-full" @click="addSliderImage" />
                            </div>
                        </div>
                    </section>

                </section>

                <section class="bg-gray-200 p-3 rounded-lg my-2">
                    <div class="p-field">
                        <label for="block2Image">Block 2 Image URL</label>
                        <!-- <InputText id="block2Image" v-model="edit_reference_single_page_data.data.block2.image" class="w-full" /> -->
                        <Image preview :src="edit_reference_single_page_data.data.block2.image" class="w-full shadow-2" v-if="edit_reference_single_page_data.data.block2.image" />
                        <FileUpload mode="basic" name="file[]" url="/" accept="image/*" :maxFileSize="10000000" :auto="true" chooseLabel="Block2 Bild" customUpload
                            @uploader="file_upload($event, edit_reference_single_page_data.slug + '_reference_' + String(edit_reference_single_page_data.data.block2.image) + '.webp', 'website/images/', 'reference_image_block2', edit_reference_single_page_data.index)" />

                    </div>
                    <div class="p-field">
                        <label for="block2Title">Block 2 Titel</label>
                        <InputText id="block2Title" v-model="edit_reference_single_page_data.data.block2.title" class="w-full" />
                    </div>
                    <div class="p-field">
                        <label for="block2Text">Block 2 Text</label>
                        <Textarea id="block2Text" v-model="edit_reference_single_page_data.data.block2.text" class="w-full" rows="4" />
                    </div>
                </section>
                <section class="bg-gray-200 p-3 rounded-lg my-2">
                    <Button label="Speichern" icon="pi pi-save" class="w-auto p-button-success mr-2" type="submit" @click="save_references_single_page" />
                    <Button label="Abbrechen" class="w-auto p-button-danger" @click="edit_page_references_dialog_single = false" />
                </section>
            </form>

        </div>


        <div v-if="edit_reference_single_page_data.theme == 'detail2'">

            <form @submit.prevent="submitForm">

                <section class="bg-gray-200 p-3 rounded-lg my-2">
                    <h3 class=" font-medium text-xl">Meta</h3>
                    <div class="my-2">
                        <label for="title">Titel</label>
                        <InputText id="title" v-model="edit_reference_single_page_data.title" class="w-full" />
                    </div>
                    <div class="my-2">
                        <label for="slug">Slug</label>
                        <InputText id="slug" v-model="edit_reference_single_page_data.slug" class="w-full" />
                    </div>
                    <div class="my-2">
                        <label for="image">Image URL</label>
                        <Image preview :src="edit_reference_single_page_data.image" class="w-full shadow-2" />
                        <FileUpload mode="basic" name="file[]" url="/" accept="image/*" :maxFileSize="10000000" :auto="true" chooseLabel="Referenz Bild" customUpload @uploader="file_upload($event, edit_reference_single_page_data.slug + '_reference_' + String(edit_reference_single_page_data.index) + '.webp', 'website/images/', 'reference_image', edit_reference_single_page_data.index)" />
                    </div>
                </section>
                <section class="bg-gray-200 p-3 rounded-lg my-2">
                    <div class="p-field">
                        <label for="topimage">Top Image</label>
                        <!-- <InputText id="topimage" v-model="edit_reference_single_page_data.topimage" class="w-full" /> -->
                        <Image preview :src="edit_reference_single_page_data.data.topimage" class="w-full shadow-2" v-if="edit_reference_single_page_data.data.topimage" />
                        <FileUpload mode="basic" name="file[]" url="/" accept="image/*" :maxFileSize="10000000" :auto="true" chooseLabel="Top Image" customUpload @uploader="file_upload($event, edit_reference_single_page_data.slug + '_reference_' + String(getRandomId(6)) + '.webp', 'website/images/', 'reference_image_topimage', edit_reference_single_page_data.index)" />
                    </div>
                    <div class="p-field">
                        <label for="sub">Sub</label>
                        <InputText id="sub" v-model="edit_reference_single_page_data.data.sub" class="w-full" />
                    </div>
                </section>
                <section class="bg-gray-200 p-3 rounded-lg my-2">
                    <div class="p-field">
                        <label for="block1Title">Block 1 Title</label>
                        <InputText id="block1Title" v-model="edit_reference_single_page_data.data.block1.title" class="w-full" />
                    </div>
                    <div class="p-field">
                        <label for="block1Text">Block 1 Text</label>
                        <Textarea id="block1Text" v-model="edit_reference_single_page_data.data.block1.text" class="w-full" rows="4" />
                    </div>
                    <section class="bg-gray-200 p-3 rounded-lg my-2">
                        <div class="p-field">
                            <label for="images">Bilder</label>
                            <div class="my-4">
                                <div class="flex flex-wrap ">
                                    <div v-for="(image, index) in edit_reference_single_page_data.data.images" :key="index" class="p-2 w-1/2 sm:w-1/3 lg:w-1/4">
                                        <div class="relativ">
                                            <Image preview :src="image" class="w-full h-full object-cover shadow-2" v-if="image" />
                                            <FileUpload mode="basic" name="file[]" url="/" accept="image/*" :maxFileSize="10000000" :auto="true" chooseLabel="Galerie Bild" customUpload @uploader="(event) => file_upload(event, `${edit_reference_single_page_data.slug}_reference_${getRandomId(6)}.webp`, 'website/images/', 'reference_image_gallery', index)" class=" cursor-pointer" />
                                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-outlined absolut top-2 right-2" @click="removeImage(index)" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="my-4">
                                <Button label="Neues Bild" icon="pi pi-plus" class="p-button-outlined h-full w-full" @click="addImage" />
                            </div>
                        </div>
                    </section>
                </section>
                <section class="bg-gray-200 p-3 rounded-lg my-2">
                    <div class="p-field">
                        <label for="block2Image">Block 2 Image URL</label>
                        <!-- <InputText id="block2Image" v-model="edit_reference_single_page_data.data.block2.image" class="w-full" /> -->
                        <Image preview :src="edit_reference_single_page_data.data.block2.image" class="w-full shadow-2" v-if="edit_reference_single_page_data.data.block2.image" />
                        <FileUpload mode="basic" name="file[]" url="/" accept="image/*" :maxFileSize="10000000" :auto="true" chooseLabel="Block2 Bild" customUpload
                            @uploader="file_upload($event, edit_reference_single_page_data.slug + '_reference_' + String(edit_reference_single_page_data.data.block2.image) + '.webp', 'website/images/', 'reference_image_block2', edit_reference_single_page_data.index)" />

                    </div>
                    <div class="p-field">
                        <label for="block2Title">Block 2 Title</label>
                        <InputText id="block2Title" v-model="edit_reference_single_page_data.data.block2.title" class="w-full" />
                    </div>
                    <div class="p-field">
                        <label for="block2Text">Block 2 Text</label>
                        <Textarea id="block2Text" v-model="edit_reference_single_page_data.data.block2.text" class="w-full" rows="4" />
                    </div>
                </section>
                <section class="bg-gray-200 p-3 rounded-lg my-2">
                    <Button label="Speichern" icon="pi pi-save" class="w-auto p-button-success mr-2" type="submit" @click="save_references_single_page" />
                    <Button label="Abbrechen" class="w-auto p-button-danger" @click="edit_page_references_dialog_single = false" />
                </section>
            </form>
        </div>


        <div v-if="edit_reference_single_page_data.theme == 'detail3'">

            <form @submit.prevent="submitForm">
                <section class="bg-gray-200 p-3 rounded-lg my-2">
                    <h3 class=" font-medium text-xl">Meta</h3>
                    <div class="my-2">
                        <label for="title">Titel</label>
                        <InputText id="title" v-model="edit_reference_single_page_data.title" class="w-full" />
                    </div>
                    <div class="my-2">
                        <label for="slug">Slug</label>
                        <InputText id="slug" v-model="edit_reference_single_page_data.slug" class="w-full" />
                    </div>
                    <div class="my-2">
                        <label for="image">Image URL</label>
                        <Image preview :src="edit_reference_single_page_data.image" class="w-full shadow-2" />
                        <FileUpload mode="basic" name="file[]" url="/" accept="image/*" :maxFileSize="10000000" :auto="true" chooseLabel="Referenz Bild" customUpload @uploader="file_upload($event, edit_reference_single_page_data.slug + '_reference_' + String(edit_reference_single_page_data.index) + '.webp', 'website/images/', 'reference_image', edit_reference_single_page_data.index)" />
                    </div>
                </section>
                <section class="bg-gray-200 p-3 rounded-lg my-2">
                    <div class="p-field">
                        <label for="images">Bilder</label>
                        <div class="my-4">
                            <div class="flex flex-wrap ">
                                <div v-for="(image, index) in edit_reference_single_page_data.data.images" :key="index" class="p-2 w-1/2 sm:w-1/3 lg:w-1/4">
                                    <div class="relativ">
                                        <Image preview :src="image" class="w-full h-full object-cover shadow-2" v-if="image" />
                                        <FileUpload mode="basic" name="file[]" url="/" accept="image/*" :maxFileSize="10000000" :auto="true" chooseLabel="Galerie Bild" customUpload @uploader="(event) => file_upload(event, `${edit_reference_single_page_data.slug}_reference_${getRandomId(6)}.webp`, 'website/images/', 'reference_image_gallery', index)" class=" cursor-pointer" />
                                        <Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-outlined absolut top-2 right-2" @click="removeImage(index)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="my-4">
                            <Button label="Neues Bild" icon="pi pi-plus" class="p-button-outlined h-full w-full" @click="addImage" />
                        </div>
                    </div>
                </section>
                <section class="bg-gray-200 p-3 rounded-lg my-2">
                    <Button label="Speichern" icon="pi pi-save" class="w-auto p-button-success mr-2" type="submit" @click="save_references_single_page" />
                    <Button label="Abbrechen" class="w-auto p-button-danger" @click="edit_page_references_dialog_single = false" />
                </section>
            </form>
        </div>

    </Dialog>
</template>