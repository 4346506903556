import {
    Bars3Icon,
    CalendarIcon,
    HomeIcon,
    ArrowUpIcon,
    ArrowUpOnSquareIcon,
    MagnifyingGlassCircleIcon,
    MapIcon,
    MegaphoneIcon,
    UserGroupIcon,
    XMarkIcon,
    InboxIcon,
    UsersIcon,
    CogIcon,
    DocumentIcon,
    QuestionMarkCircleIcon,
    ArrowLeftOnRectangleIcon,
    ArrowDownTrayIcon,
    EnvelopeIcon,
    BellIcon,
    EnvelopeOpenIcon, MagnifyingGlassIcon, Bars3BottomLeftIcon, BookOpenIcon, ClipboardDocumentIcon, CloudIcon, ShoppingCartIcon, ShoppingBagIcon, FolderIcon, GlobeAltIcon
} from '@heroicons/vue/24/outline';

export default [
    { name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: false, order: 0 },
    { name: 'Leads', href: '/dashboard/Leads', icon: FolderIcon, current: false, order: 1 },
    { name: 'Website', href: '/dashboard/Website', icon: GlobeAltIcon, current: false, order: 2 },
    { name: 'Blog', href: '/dashboard/Blog', icon: DocumentIcon, current: false, order: 3 },
    { name: 'Settings', href: '/dashboard/Settings', icon: CogIcon, current: false, order: 6 }
]
;