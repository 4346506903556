<template>
    <div class="flex flex-wrap sm:flex-row  w-full">

        <div class="w-full my-1 sm:w-2/4">

            <div v-if="!isEditing" class="m-4">

                <div v-if="image">
                    <img :src="image" class="shadow-2 object-cover relative" />
                    <div class=" relative -top-16 left-3 w-24">
                        <Button @click="toggleEdit" class="p-button relative" icon="pi pi-pencil" />
                    </div>
                </div>

                <div v-else>
                    <Button @click="toggleEdit" class="p-button w-1/4" v-tooltip.right="`Bild auswählen`" icon="pi pi-plus" />
                </div>

            </div>

            <div v-if="isEditing" class="mt-3">

                <cropper ref="croppy" :src="image" v-if="image" :auto-zoom="true" :auto-center="false" class="w-full my-3 border-2" :stencil-props="stencilProps" :default-size="defaultSize" />


                <ImagePlaceholder v-if="!image" classes="w-full h-56 " />
                <div class=" flex flex-row w-full my-1" v-if="image">

                    <div class="w-full mx-1">
                        <Button label="Speichern" @click="crop_image" class="w-full p-success" icon="pi pi-file-edit" />
                    </div>
                    <div class="w-full mx-1">
                        <Button icon="pi pi-times" label="Entfernen" class="w-full p-button-danger" @click="removeImage" v-tooltip.bottom="'Bild löschen'" />
                    </div>
                    <div class="w-full mx-1">
                        <Button icon="pi pi-times" label="Abbruch" class="w-full p-button-warning" @click="toggleEdit" v-tooltip.bottom="'Schließen'" />
                    </div>
                </div>
                <div class="w-full" v-if="isDeleting">
                    <Button icon="pi pi-times" label="Bild aus Datensatz entfernen?" class="w-full p-button-danger my-1" @click="deleteImage" v-tooltip.bottom="'Bild löschen'" />
                </div>
            </div>
        </div>

        <div class="w-full my-5 sm:w-2/4">
            <div v-if="isEditing" class="mt-3">
                <div v-if="image_library.length" class="flex flex-wrap justify-center">
                    <template v-for="(img, index) of image_library" :key="index">
                        <img @click="selectImage(img)" :src="img.image" class="shadow-2 m-2 shadow-2 cursor-pointer h-24 w-24 object-cover inline" />
                    </template>
                </div>
                <div v-else>
                    <MainAlert>Keine Bilder vorhanden. Bitte laden Sie zunächst Bilder in die Bibliothek hoch</MainAlert>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';
import { Cropper, Preview } from 'vue-advanced-cropper'
import ImagePlaceholder from '@/core/components/UI/Main-Image-Placeholder.vue'
import MainAlert from '@/core/components/UI/Main-Alert.vue';

const props = defineProps({
    edit: {
        type: Boolean,
        default: false,
    },
    image: {
        type: String,
        default: '',
    },
    image_library: {
        type: Array,
        default: () => [],
    },
    stencilProps: {
        type: Object,
        default: () => { },
    }
});

const emits = defineEmits(['update', 'delete'])

const isEditing = ref(props.edit);
const isDeleting = ref(false);
const image = ref(props.image);
const croppy = ref(null);

const toggleEdit = () => {
    isEditing.value = !isEditing.value;
}

const removeImage = () => {
    isDeleting.value = true
    image.value = false
}

const selectImage = (img) => {
    isDeleting.value = false;
    image.value = img.image;
}

const deleteImage = () => {
    isDeleting.value = false;
    emits('delete', true);
}
const crop_image = () => {
    isEditing.value = false
    image.value = croppy.value.getResult().canvas.toDataURL()
    emits('update', croppy.value);
}

const defaultSize = ({ imageSize, visibleArea }) => {
    return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height,
    };
}
</script>

