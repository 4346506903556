<template>
    <Dialog header="Bilder hochladen" :modal="true" class="">
        <div class="">
            <div class="">
                <FileUpload name="demo[]" :customUpload="true" @uploader="upload_library_image" :multiple="true" accept="image/*" :maxFileSize="10000000" chooseLabel="Bilder auswählen" uploadLabel="Hochladen" cancelLabel="Abbrechen" class="cursor-pointer">
                    <template #empty>
                        <div class="grid justify-items-center" v-show="!loading">
                            <div class="my-2"><i class="pi pi-upload h-14 w-14 text-primary-700" style="font-size: 4rem;"></i></div>
                            <div class="my-2">Hochladen per Drag & Drop</div>
                        </div>
                        <div class="grid justify-items-center" v-show="loading">
                            <LoadingSpinner size="16" />
                            Upload läuft, das Fenster wird automatisch geschlossen
                        </div>
                    </template>
                </FileUpload>
            </div>
        </div>
    </Dialog>
</template>

<script setup>

import { ref, defineEmits } from 'vue';
import axios from 'axios';
import LoadingSpinner from '@/core/components/UI/Loading-Spinner.vue';

const loading = ref(false);
const props = defineProps(['path', 'slug'])
const emit = defineEmits(['data', 'finished']);

const returnData = res => {
    return emit('data', res);
}

const finished = res => {
    return emit('finished', res);
}


const upload_library_image = async (e) => {
    loading.value = true;
    try {
        for (let i = 0; i < e.files.length; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(e.files[i]);
            reader.onload = async () => {
                const payload = { filename: e.files[i].name, file: reader.result };
                const response = await axios.post(
                    `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${props.slug}/${props.path}`,
                    payload
                );
                returnData(response.data);
            };
            await new Promise(resolve => setTimeout(resolve, 2000));

            if (i + 1 == e.files.length) {
                finished(true);
                loading.value = false;
            }

        }
    } catch (error) {
        console.error(error);
    }
};
</script>