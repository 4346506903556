<script setup>
import { ref, defineProps, defineEmits } from 'vue'
import MenuButton from './Components/Menu-Button.vue'
import DataView from './Components/Data-View.vue'
import ModalButtons from './Components/Modal-Menue.vue'
// local variables
const editDialog = ref(false)
const BLOCK_NAME = "standard_title";

// define props
const props = defineProps({
    template_type: { type: String, required: true },
    index: { type: Number, required: false },
    data: { type: Object, required: true },
    workflow_id: { type: String, required: false },
    image_library: { type: Object, required: false },
})
// define emits
const emits = defineEmits(['blockCallback'])

// fields
const text_content = ref(props.data.text_content)
const template = ref(BLOCK_NAME)
const type = ref(props.data.type)
const types = ref([

    { label: 'H2', value: 'h2' },
    { label: 'H3', value: 'h3' },
    { label: 'H4', value: 'h4' },
    { label: 'H5', value: 'h5' },
    { label: 'H6', value: 'h6' }
])

// functions
const edit_block = () => {
    editDialog.value = true
}

const save_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "text_content": text_content.value,
                "type": type.value
            },
            "action": "save",
            "index": props.index
        }
    )
    editDialog.value = false
}

const delete_block = () => {
    emits('blockCallback',
        {
            "action": "delete",
            "index": props.index
        }
    )
    editDialog.value = false
}

const add_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "text_content": "Einen Standard Titel",
                "type": "h2"
            },
            "action": "add",
            "index": props.index
        }
    )
}
const toggleDialog = () => {
    editDialog.value = false
}
</script>
<template>
    <!--------------------------------------------------->
    <!------------------ Menu View ---------------------->
    <!--------------------------------------------------->
    <MenuButton v-if="props.template_type == 'menu'" :add_block="add_block" button="pi-minus" name="Standard Titel" />
    <!--------------------------------------------------->
    <!------------------ Data View ---------------------->
    <!--------------------------------------------------->
    <DataView v-if="props.template_type == 'data'" :edit_block="edit_block" name="Standard Titel">
        <Divider />
        <div class="text-center p-2 title_preview">
            <h2 v-if="props.data.type == 'h2'">{{ props.data.text_content }}</h2>
            <h3 v-if="props.data.type == 'h3'">{{ props.data.text_content }}</h3>
            <h4 v-if="props.data.type == 'h4'">{{ props.data.text_content }}</h4>
            <h5 v-if="props.data.type == 'h5'">{{ props.data.text_content }}</h5>
            <h6 v-if="props.data.type == 'h6'">{{ props.data.text_content }}</h6>
        </div>
    </DataView>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editDialog" :style="{ width: '80%' }" header="Block bearbeiten" :modal="true" class="p-fluid">
        <div class="">
            <ModalButtons :saveBlock="save_block" :deleteBlock="delete_block" :toggleDialog="toggleDialog" />
        </div>
        <div>
            <div class="my-3">
                <label for="text_content">Titel Inhalt</label>
                <InputText v-model="text_content" id="text_content" type="text" :autoResize="true" />
            </div>
            <div class="my-3">
                <label for="title">Titel Typ</label>
                <Dropdown v-model="type" :options="types" optionLabel="label" optionValue="value" id="title" />
            </div>
        </div>
    </Dialog>
</template>

<style>
.title_preview h1 {
    @apply font-bold leading-tight text-gray-900 text-3xl
}

.title_preview h2 {
    @apply font-bold leading-tight text-gray-900 text-2xl
}

.title_preview h3 {
    @apply font-bold leading-tight text-gray-900 text-xl
}
.title_preview h4 {
    @apply font-bold leading-tight text-gray-900 text-xl
}
.title_preview h5 {
    @apply font-bold leading-tight text-gray-900 text-base
}
</style>