<template>
    <div class="hidden sm:block">
        <TabGroup>
            <TabList>
                <Tab as="template" v-slot="{ selected }">
                    <button :class="[selected ? 'border-primary-500 text-primary-600 border-transparent' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'group inline-flex items-center border-b-2 p-4 text-sm font-medium']">
                        <CircleStackIcon :class="[selected ? 'border-primary-500 text-primary-600' : 'text-gray-400 group-hover:text-gray-500', '-ml-0.5 mr-2 h-5 w-5']" aria-hidden="true" />
                        <span>Blog</span>
                    </button>
                </Tab>
                <Tab as="template" v-slot="{ selected }">
                    <button :class="[selected ? 'border-primary-500 text-primary-600 border-transparent' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'group inline-flex items-center border-b-2 p-4 text-sm font-medium']">
                        <LinkIcon :class="[selected ? 'border-primary-500 text-primary-600' : 'text-gray-400 group-hover:text-gray-500', '-ml-0.5 mr-2 h-5 w-5']" aria-hidden="true" />
                        <span>Kategorien</span>
                    </button>
                </Tab>

            </TabList>

            <TabPanels class="p-8">
                <TabPanel>
                    <GartenkulturBlog />
                </TabPanel>
                <TabPanel>
                    <GartenkulturBlogCategories />
                </TabPanel>
            </TabPanels>
        </TabGroup>
    </div>


    <div class="sm:hidden">
        <label for="tabs" class="sr-only">Select a tab</label>
        <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-primary-500 focus:ring-primary-500">
            <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
        </select>
    </div>
</template>

<script setup>
import GartenkulturBlog from './blog/Gartenkultur-Blog.vue';
import GartenkulturBlogCategories from './blog/Gartenkultur-BlogCategories.vue';

import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
import { BuildingOfficeIcon, UserIcon, CircleStackIcon, LinkIcon } from '@heroicons/vue/20/solid'

const tabs = []
</script>